import * as React from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Hidden from '@material-ui/core/Hidden'
import PhoneIcon from '@material-ui/icons/Phone'
import MapMarkerIcon from '@material-ui/icons/Room'
import ChatIcon from '@material-ui/icons/Chat'
import Divider from '@material-ui/core/Divider'
import { MenuContext } from '../contexts/menu-context'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #efefef',
      zIndex: 999
    },
    label: {
      // transition: 'none'
    }
  })

// const MenuWrapper = styled(Rebass.Flex)`
//   position: fixed;
//   bottom: 0;
//   background-color: #fff;
//   box-shadow: 0 2px 14px 0 rgba(7, 29, 59, 0.2);
//   max-width: 100vw;
// `

export const MobileActionMenu = withStyles(styles)(
  ({ classes }: WithStyles<typeof styles>) => {
    const data = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            tollFree
          }
        }
      }
    `)

    const { tollFree } = data.site.siteMetadata

    const { toggleModal } = React.useContext(MenuContext)

    return (
      <Hidden smUp>
        <BottomNavigation
          value={-1}
          onChange={(_, value) => {
            setTimeout(() => {
              switch (value) {
                case 'call':
                  return (window.location.href = `tel:${tollFree}`)
                case 'map':
                  toggleModal()
                  return
                case 'chat':
                  // return (window.location.href = `mailto:info@ari.dental?subject=Dental Appointment`)
                  return (window.location.href = `sms://416.340.977.1414`)
                default:
                  return
              }
            }, 300)
          }}
          showLabels={true}
          className={classes.root}
        >
          <BottomNavigationAction
            classes={{ label: classes.label }}
            icon={<PhoneIcon />}
            value={'call'}
          />
          <Divider orientation="vertical" />
          <BottomNavigationAction icon={<MapMarkerIcon />} value={'map'} />
          <Divider orientation="vertical" />
          <BottomNavigationAction icon={<ChatIcon />} value={'chat'} />
        </BottomNavigation>
      </Hidden>
    )
  }
)
