import React from 'react'

export const MenuContext = React.createContext({
  open: false,
  toggleOpen: () => {},
  toggleModal: () => {},
  mapLocationModalOpen: false
})

export const MenuProvider = ({ children }) => {
  const [open, setOpen] = React.useState(false)
  const [mapLocationModalOpen, setMapLocationModal] = React.useState(false)
  const menuContext = {
    open,
    toggleOpen: () => setOpen(!open),
    mapLocationModalOpen,
    toggleModal: () => {
      setMapLocationModal(!mapLocationModalOpen)
    }
  }
  return (
    <MenuContext.Provider value={menuContext}>{children}</MenuContext.Provider>
  )
}

export const { Consumer } = MenuContext
