import React from 'react'
import PropTypes from 'prop-types'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

import { HeroProvider } from '../contexts/hero-context'
import { MenuProvider } from '../contexts/menu-context'
import { Notifications } from '../sections/notifications';
import { ContactBar } from './contact-bar'
import { Footer } from './footer'
import { Header } from './header'
import { SideMenu } from './side-menu'
import { MobileActionMenu } from './mobile-action-menu'

// const theme = require('../common/theme')

import '../assets/global.css'
import '../assets/fonts/fonts.css'

import { MapLocationModal } from './map-location-modal'

const defaultTheme = createMuiTheme()

const theme = createMuiTheme({
  palette: {
    primary: { main: '#3C8AEF' }, // Purple and green play nicely together.
    secondary: { main: '#11cb5f' }, // This is just green.A700 as hex.
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF'
    }
  },
  typography: {
    body1: {
      fontFamily: 'Open Sans, Helvetica, Arial, sans-serif',
      lineHeight: 2
    },
    button: {
      fontFamily: 'Open Sans, Helvetica, Arial, sans-serif',
      fontWeight: 600,
      textTransform: 'none'
    },
    h4: {
      fontWeight: 300
    },
    h6: {
      fontFamily: 'Avenir',
      fontWeight: 400
    },
    overline: {
      fontFamily: 'Open Sans, Helvetica, Arial, sans-serif'
    }
  }
})

export const Layout = ({ children, langKey }) => {
  const sideMenu = React.useMemo(() => <SideMenu />, [])
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MenuProvider>
        <HeroProvider>
          <ContactBar />

          <Header />

          <main id="page-wrap">{children}</main>

          <Footer langKey={langKey} />

          <MobileActionMenu />

          <MapLocationModal />

          {sideMenu}
        </HeroProvider>
      </MenuProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
