import React, { useEffect } from 'react'
import Sticky from 'react-stickynode'
import {
  createStyles,
  makeStyles,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core/styles'
import MUIAppBar, { AppBarProps } from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
// import MenuIcon from '@material-ui/icons/Menu'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
// import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden';
import { Link as GatsbyLink, GatsbyLinkProps, navigate } from 'gatsby'
// import * as Scroll from 'react-scroll';
import HamburgerMenu from 'react-hamburger-menu';
import { MenuContext } from '../../contexts/menu-context'
// interface ScrollProps {
//   scrollStatus?: any
// }

const AriDentalLogo = require('../../assets/ari-dental-logo.svg')

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    buttonRoot: {
      // marginRight: theme.spacing(2),
      // marginRight: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingTop: theme.spacing(1.2),
      paddingBottom: theme.spacing(1.2),
    },
    buttonLabel: {
      whiteSpace: 'pre',
      color: "#3c8aef",
    },
    // title: {
    //   flexGrow: 1,
    // },
    appBar: {
      // height: '64px'
      // height: '4em'
    },
    toolbar: {
      padding: 0
    },
    container: {
      padding: 0,
      flexDirection: 'row'
    },
    // inner: {
    //   height: `calc(60px + 5px + 5px)`,
    //   flexDirection: 'row',
    //   display: 'flex',
    //   paddingLeft: 0
    //   // paddingLeft: theme.spacing(2)
    // },
    logoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      // marginTop: theme.spacing(1),
      // marginBottom: theme.spacing(1)
      // marginTop: '5px',
      // marginBottom: '5px'
    },

    logo: {
      // height: '64px',
      height: '4em',
      display: 'flex',
      flexDirection: 'column'
    },

    link: {
      cursor: 'pointer',
      // flexDirection: 'column',
      // height: '64px',
      height: '4em',
      // maxHeight: '60px',
      textDecoration: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '16px',

      marginRight: '10px',
      color: 'rgba(7,29,59, 0.5)',

      '&:hover': {
        // color: '#071D3B',
        boxShadow: 'inset 0 -2.5px #3C89EF'
      },
      '&:visited': {
        color: '#071D3B'
      }
    },
    appointLink: {
      textDecoration: 'none'
    },
    innerLink: {
      // height: '64px',
      height: '4em',
      alignItems: 'center',
      justifyContent: 'flex-start',
      display: 'flex',
      paddingLeft: '20px',
      paddingRight: '20px'
    },
    inner: {
      height: `60px`,
      paddingLeft: 0,
      display: 'flex',
      flexDirection: 'row'
    },
    burgerMenu: {
      marginLeft: 'auto',
      marginRight: -theme.spacing(4)
    }
  })

// const Link = styled(GatsbyLink)((theme: Theme & GatsbyLinkProps<{}>) => ({
//   cursor: 'pointer',
//   flexDirection: 'column',
//   textDecoration: 'none',
//   alignItems: 'center',
//   justifyContent: 'center',

//   marginRight: '10px',

//   '&:hover': {
//     color: '#071D3B',
//     boxShadow: 'inset 0 -2.5px #3C89EF'
//   },
//   '&:visited': {
//     color: '#071D3B'
//   },
// }))

// const LinkInner = styled('div')({
//   height: `64px`,
//   alignItems: 'center',
//   justifyContent: 'center',
//   display: 'flex',
//   paddingLeft: '15px',
//   paddingRight: '15px',
//   // display: 'block'
// })

const LogoWrapper = withStyles((theme: Theme) => ({
  root: {
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1)
  }
}))(Box)

const isActive = (classes) => ({ isCurrent, isPartiallyCurrent, ...other }) => {
  if (isCurrent && isPartiallyCurrent) {
    return { className: `${classes.active} ${classes.link}` }
  }
};

// const NavLink = withStyles((theme: Theme) => ({
//   active: {
//     color: '#071D3B !important',
//     boxShadow: 'inset 0 -3px #3C89EF',
//     textDecoration: 'none',
//     fontSize: '16px',
//   },
//   link: {
//     cursor: 'pointer',
//     // flexDirection: 'column',
//     height: '64px',
//     // maxHeight: '60px',
//     textDecoration: 'none',
//     alignItems: 'center',
//     justifyContent: 'center',
//     fontSize: '16px',

//     marginRight: '10px',
//     color: 'rgba(7,29,59, 0.5)',

//     '&:hover': {
//       color: '#071D3B',
//       boxShadow: 'inset 0 -3px #3C89EF'
//     },
//     '&:visited': {
//       color: '#071D3B'
//     },
//   },
//   innerLink: {
//     height: '64px',
//     alignItems: 'center',
//     justifyContent: 'flex-start',
//     display: 'flex',
//     paddingLeft: '20px',
//     paddingRight: '20px'
//   }
// }))(
//   ({
//     classes,
//     title,
//     to
//   }: GatsbyLinkProps<{}> &
//     WithStyles<typeof styles> & { to: string; title: string } & any) => (
//     <GatsbyLink
//       to={to}
//       className={classes.link}
//       getProps={isActive(classes)}
//       activeClassName={classes.active}
//     >
//       <div className={classes.innerLink}>{title}</div>
//     </GatsbyLink>
//   )
// )

const NavLink = withStyles((theme: Theme) => ({
  active: {
    color: '#071D3B !important',
    boxShadow: 'inset 0 -3px #3C89EF',
    textDecoration: 'none',
    // fontSize: '16px',
    fontSize: '20px'
  },
  link: {
    cursor: 'pointer',
    // flexDirection: 'column',
    height: '64px',
    // maxHeight: '60px',
    textDecoration: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',

    marginRight: '10px',
    color: 'rgba(7,29,59, 0.5)',

    '&:hover': {
      color: '#071D3B',
      boxShadow: 'inset 0 -3px #3C89EF'
    },
    '&:visited': {
      color: '#071D3B'
    },
  },
  innerLink: {
    height: '64px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    display: 'flex',
    paddingLeft: '20px',
    paddingRight: '20px'
  }
}))(
  ({
    classes,
    title,
    to
  }: GatsbyLinkProps<{}> &
    WithStyles<typeof styles> & { to: string; title: string } & any) => {
    return (
      <GatsbyLink
        to={to}
        className={classes.link}
        getProps={isActive(classes)}
      >

        <div className={classes.innerLink}>{title}</div>
      </GatsbyLink>
    );
  });


// interface AppBarComponentProps extends WithStyles<typeof styles> {
//   text: string;
// }

// const Component: React.FunctionComponent<AppBarComponentProps & WithStyles<typeof styles>> = ({
//   classes,
//   text,
// }) => <div className={classes.root}>{text}</div>;

const AppBar = withStyles((theme: Theme) => ({
  root: (props: { scrollStatus: Sticky.StatusCode }) => ({
    color: 'rgba(7,29,59, 0.5)',
    backgroundColor:
      props.scrollStatus === Sticky.STATUS_ORIGINAL
        ? 'rgba(60, 138, 239, 0.07)'
        : '#ffffff',
    boxShadow:
      props.scrollStatus === Sticky.STATUS_ORIGINAL
        ? 'none'
        : '0px 3px 34px rgba(7, 29, 59, 0.1)'
  })
}))((props: AppBarProps & WithStyles<typeof styles>) => (
  <MUIAppBar {...props} />
))

export const Header = withStyles(styles)(
  ({ classes }: WithStyles<typeof styles>) => {
    const { open, toggleOpen } = React.useContext(MenuContext);
    // useEffect(() => {
    //   console.log("ipad detect:", navigator.userAgent.match(/iPad/i));
    // })
    return (
      <Sticky innerZ={2}>
        {({ status }) => (
          <div className={classes.root}>
            <AppBar
              elevation={0}
              position="static"
              className={classes.appBar}
              scrollStatus={status}
            >
              <Container>
                <Toolbar>
                  <GatsbyLink to="/en">
                    <LogoWrapper>
                      <AriDentalLogo className={classes.logo} />
                    </LogoWrapper>
                  </GatsbyLink>
                  <Hidden implementation="js" mdDown>
                    <Container maxWidth="md">
                      <Toolbar>
                        <NavLink to="/en" title="Home" />
                        <NavLink to="/en/resources" title="Resources" />
                        <NavLink to="/en/services" title="Services" />
                        <NavLink to="/en/our-team" title="Team" />
                        <NavLink to="/en/news" title="News" />
                        <NavLink to="/en/contact-us" title="Contact" />
                      </Toolbar>
                    </Container>
                    
                    <GatsbyLink
                      to="/en/resources/forms/request-appointment"
                      className={classes.appointLink}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        classes={{
                          root: classes.buttonRoot,
                          label: classes.buttonLabel
                        }}
                      >
                        Make an Appointment
                      </Button>
                    </GatsbyLink>
                  </Hidden>
                  <Hidden implementation="js" lgUp>
                    <Toolbar className={classes.burgerMenu}>
                      <Box padding={3}>
                        <HamburgerMenu
                          isOpen={open}
                          menuClicked={toggleOpen}
                          width={33}
                          height={23}
                          strokeWidth={3}
                          rotate={0}
                          color='#3C8AEF'
                          borderRadius={5}
                          animationDuration={0.4}
                        />
                      </Box>
                    </Toolbar>
                  </Hidden>
                </Toolbar>
              </Container>
            </AppBar>
          </div>
        )}
      </Sticky>
    )
  }
)
