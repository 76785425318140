import * as React from "react";

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Link as RouterLink, GatsbyLinkProps, navigate } from 'gatsby'
import Paper from '@material-ui/core/Paper';
import { styled, withStyles } from '@material-ui/styles'
import { graphql, useStaticQuery } from 'gatsby';
import { Theme } from "@material-ui/core";
import { path } from 'ramda'
import Grid from "@material-ui/core/Grid";
import Image from 'material-ui-image';

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const LinkComponent = React.forwardRef<HTMLAnchorElement, Omit<GatsbyLinkProps<{}>, 'ref'>>((props, ref) => (
  // @ts-ignore
  <RouterLink innerRef={ref} {...props} />
))

const BACKGROUND_COLOR = '#F5F7FA';
const BORDER_COLOR = '#E4E7EB'

const HR = styled('div')({
  height: '1px',
  width: '100%',
  backgroundColor: BORDER_COLOR
})

const Wrapper = styled(Box)({
  backgroundColor: BACKGROUND_COLOR
})

const TeamCard = withStyles((theme: Theme) => ({
  root: {
    marginTop: '15px',
    borderTopLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    padding: theme.spacing(2),
  }
}))(Paper)

const MemberName = withStyles((theme: Theme) => ({
  root: {
    fontSize: '20px',
    color: '#071D3B',
    letterSpacing: 0,
    // textAlign: 'center'
  }
}))(Typography);

const MemberRole = withStyles((theme: Theme) => ({
  root: {
    fontFamily: 'Open Sans',
    opacity: 0.5,
    fontSize: '20px',
    color: '#071D3B',
    letterSpacing: 0,
    // textAlign: 'center'
  }
}))(Typography)

const AvatarWrapper = withStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
    borderRadius: '50%',
    border: '2px solid #fff',
    boxShadow: '0 6px 24px 0 rgba(7,29,59,0.17)',
  }
}))(Box)

const MemberBio = withStyles((theme: Theme) => ({
  root: {
    fontFamily: 'Open Sans',
    opacity: 0.5,
    fontSize: '20px',
    color: '#071D3B',
    letterSpacing: 0,
    // textAlign: 'center'
  }
}))(Typography)

export default () => {
    const data = useStaticQuery(graphql`
    {
      allContentfulPerson (filter: { node_locale: { eq: "en" } }, sort: { fields: [order], order: ASC}){
        edges {
          node {
            name
            title
            biography {
              childMarkdownRemark {
                internal {
                  content
                }
              }
            }
            image {
              localFile {
                publicURL
              }
            }
          }
        }
      }
        allContentfulLayoutCopy(
          filter: { node_locale: { eq: "en" }, title: { eq: "Team > Header" } } 
          limit: 1
        ) {
          edges {
            node {
              copy {
                childMarkdownRemark {
                  rawMarkdownBody
                }
              }
            }
          }
      }
    }
  `);
  const people = data.allContentfulPerson.edges.map((person) => {
    const { name, title, biography, image } = person.node;
    if (name && title && biography && image) {
      const biographyContent = biography ? biography.childMarkdownRemark.internal.content : '';
      const url = image? image.localFile.publicURL : '';
      return (
        <TeamCard>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} sm={2}>
              <AvatarWrapper>
                <Image src={url} />
              </AvatarWrapper>
            </Grid>
            <Grid item={true} xs={12} sm={10}>
              <MemberName>{name}</MemberName>
              <MemberRole>{title}</MemberRole>
              <MemberBio>{biographyContent}</MemberBio>
            </Grid>
          </Grid>
          
        
          </TeamCard>
      )
    }
  });
  const heroBody = path(['allContentfulLayoutCopy', 'edges', 0, 'node', 'copy', 'childMarkdownRemark', 'rawMarkdownBody'], data)
  return (
    <Layout>

      <SEO title="Dental Services" />

      <Container maxWidth='md'>

        <Box my={3}>
            <Breadcrumbs aria-label="breadcrumb">
            <Link component={LinkComponent} color="inherit" to="/en">
              Home
            </Link>
            <Typography color="textPrimary">Team</Typography>
          </Breadcrumbs>
        </Box>

        <HR />

        <Box mt={3}>

          <Typography variant='h4' component='h1'>
            Our Team
          </Typography>

          <Box my={4}>
            <Typography>
              {heroBody}
            </Typography>
          </Box>

        </Box>
        
      </Container>

      <Wrapper>
        <Container maxWidth='md'>
          <Box py={4}>
            {people}
          </Box>
      </Container>
      </Wrapper>

    </Layout>
  );
}
