import React, { useState } from 'react'
import * as Rebass from 'rebass'
import styled from '@emotion/styled'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { layout, space, typography } from 'styled-system'
import { opacify } from 'polished'

import { createStyles, fade, Theme, makeStyles } from '@material-ui/core/styles'
import { withStyles, WithStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'

const AriDentalLogo = require('../assets/ari-dental-logo.svg')

const LogoWrapper = withStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: theme.spacing(9)
  }
}))(Box)

const Wrapper = withStyles(() => ({
  root: {
    borderTop: '1px solid rgba(7, 29, 59, 0.1)'
  }
}))(Box)

const MenuWrapper = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flex: 0.8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(10)
  }
}))(Box)

const ColumnHeader = withStyles((theme: Theme) => ({
  root: {
    size: theme.typography.h5,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
  }
}))(Typography)

// const MenuWrapper = styled(Rebass.Flex)`
//   justify-content: space-between;
//   flex: 0.8;
// `

// MenuWrapper.defaultProps = {
//   ml: 6
// }

const MenuColumn = styled(Rebass.Flex)``

MenuColumn.defaultProps = {
  flexDirection: 'column'
}

const FooterLink = styled(Link)`
  text-decoration: none;
  color: ${opacify(0.5, 'rgba(7,29,59, 0.5)')} !important;
  opacity: 0.5;
  ${layout}
  ${typography}
  ${space}
`

FooterLink.defaultProps = {
  fontFamily: 'Open Sans',
  fontSize: [2],
  mb: 3
}

export const Footer = ({ langKey }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulService(filter: { node_locale: { eq: "en" } }) {
        edges {
          node {
            name
            slug
            node_locale
          }
        }
      }
      allContentfulDocument(filter: { node_locale: { eq: "en" } }) {
        edges {
          node {
            name
            slug
            node_locale
          }
        }
      }
    }
  `)

  const services = data.allContentfulService.edges.map((service) => (
    <FooterLink
      to={`/${service.node.node_locale}/services/${service.node.slug}`}
    >
      {service.node.name}
    </FooterLink>
  ))

  const documents = data.allContentfulDocument.edges.map((doc) => (
    <FooterLink to={`/${doc.node.node_locale}/documents/${doc.node.slug}`}>
      {doc.node.name}
    </FooterLink>
  ))

  return (
    <Hidden smDown>
      <footer>
        <Wrapper>
          <Container>
            <Box display="flex" my={7}>
              <LogoWrapper>
                <AriDentalLogo />
              </LogoWrapper>
              <MenuWrapper>
                <MenuColumn>
                  <ColumnHeader>Services</ColumnHeader>
                  {services}
                </MenuColumn>
                <MenuColumn>
                  <ColumnHeader>About</ColumnHeader>
                  <FooterLink to="/en/our-team">Team</FooterLink>
                </MenuColumn>
                <MenuColumn>
                  <ColumnHeader>Legal</ColumnHeader>
                  {documents}
                </MenuColumn>
                <MenuColumn>
                  <ColumnHeader>Contact Us</ColumnHeader>
                  <FooterLink to="/en/contact-us/#write-us">
                    Write Us
                  </FooterLink>
                  <FooterLink to="/en/contact-us/#map">Map</FooterLink>
                </MenuColumn>
              </MenuWrapper>
            </Box>
          </Container>
        </Wrapper>
      </footer>
    </Hidden>
  )
}
