import React from 'react'

export const HeroContext = React.createContext({})

export const HeroProvider = ({ children }) => {
  const [height, setHeight] = React.useState(0)
  const menuContext = {
    height,
    setHeight
  }
  return (
    <HeroContext.Provider value={menuContext}>{children}</HeroContext.Provider>
  )
}

export const { Consumer } = HeroContext
