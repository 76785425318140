import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import PersonIcon from '@material-ui/icons/Person'
import LocationIcon from '@material-ui/icons/LocationOnOutlined'
import Typography from '@material-ui/core/Typography'
import { blue } from '@material-ui/core/colors'
import { MenuContext } from '../contexts/menu-context'

const emails = ['46 John Street', '451 King Street West', '1051 Queen St West' ]

const useStyles = makeStyles({
  avatar: {
    backgroundColor: '#fff',
    color: blue[600],
    border: `1px dashed ${blue[100]}`
  }
})

export interface SimpleDialogProps {
  // open: boolean;
  // selectedValue: string;
  // onClose?: (value: string) => void;
}

export const MapLocationModal = (props: SimpleDialogProps) => {
  const classes = useStyles({})

  const { mapLocationModalOpen, toggleModal } = React.useContext(MenuContext)

  const handleClose = () => {
    toggleModal()
  }

  const handleListItemClick = (value: string) => {
    window.location.href = `https://maps.google.com/?q=${value}, Toronto, Ontario, Canada`
    toggleModal()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={mapLocationModalOpen}
    >
      <DialogTitle id="simple-dialog-title">Select Location</DialogTitle>
      <List>
        {emails.map((email) => (
          <ListItem
            button
            onClick={() => handleListItemClick(email)}
            key={email}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <LocationIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={email} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}
