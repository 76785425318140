import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link as GatsbyLink, navigate, useStaticQuery } from 'gatsby'
import * as Scroll from 'react-scroll'
import {
  createStyles,
  makeStyles,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import * as Color from 'color'
import { Picture } from 'react-responsive-picture'

import { Button } from '@material-ui/core'

import { MenuContext } from '../contexts/menu-context'

const DarkenColor = Color('rgb(60, 137, 239)')
  .darken(0.1)
  .hsl()
  .toString()

const AriList = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#071D3B'
  }
}))(List)

const AriListItem = withStyles((theme: Theme) => ({
  root: {
    borderLeft: '5px solid #3C89EF'
  }
}))(ListItem)

const AriListItemText = withStyles((theme: Theme) => ({
  primary: {
    color: '#fff'
  }
}))(ListItemText)

const BlueButton = withStyles((theme: Theme) => ({
  root: {
    // color: theme.palette.getContrastText('rgb(60, 137, 239)'),
    color: '#fff !important',
    // boxShadow: 'none',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'rgba(60, 137, 239)',
    borderColor: 'rgba(60, 137, 239)',
    // boxShadow: '0px 3px 20px rgba(60, 137, 239, 0.5)',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    // fontFamily: [
    //   'Nunito',
    //   // 'Avenir'
    // ].join(','),
    // fontWeight: 600,
    '&:hover': {
      backgroundColor: DarkenColor,
      borderColor: DarkenColor
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: DarkenColor,
      borderColor: DarkenColor
    },
    '&:focus': {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  },
  text: {
    color: '#fff'
  }
}))(Button)

export const SideMenu = () => {
  const { open, toggleOpen } = React.useContext(MenuContext)

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          tollFree
        }
      }
      ariDentalLogo: file(
        relativePath: { eq: "ari-dental-logo-white-01.svg" }
        sourceInstanceName: { eq: "assets" }
      ) {
        publicURL
      }
      calendarIcon: file(
        relativePath: { eq: "icons/calendar-icon.svg" }
        sourceInstanceName: { eq: "assets" }
      ) {
        publicURL
      }
      cellPhoneIcon: file(
        relativePath: { eq: "icons/cellphone-icon.svg" }
        sourceInstanceName: { eq: "assets" }
      ) {
        publicURL
      }
    }
  `)

  const { tollFree } = data.site.siteMetadata

  const Pages = [
    { label: 'Home', slug: '/' },
    { label: 'Resources', slug: 'resources' },
    { label: 'Services', slug: 'services' },
    { label: 'Team', slug: 'our-team' },
    { label: 'News', slug: 'news' },
    { label: 'Contact', slug: 'contact-us' }
  ]

  const clickHandler = (index: number) => (event) => {
    event.preventDefault()
    navigate(`/en/${Pages[index].slug}`)
    toggleOpen()
  }

  const menuItems = Pages.map((page, index) => (
    <AriListItem button={true} key={page.label} onClick={clickHandler(index)}>
      <AriListItemText primary={page.label} />
    </AriListItem>
  ))
  return (
    <Drawer
      variant="temporary"
      anchor="bottom"
      open={open}
      onClose={toggleOpen}
    >
      <AriList>{menuItems}</AriList>
      <AriList dense={true}>
        <ListItem>
          <Box display="flex" marginRight={1} alignItems="center">
            <Picture src={data.cellPhoneIcon.publicURL} />
          </Box>
          <AriListItemText primary={`Call Us ${tollFree}`} />
        </ListItem>
        <ListItem>
          <Box display="flex" marginRight={1} alignItems="center">
            <Picture src={data.calendarIcon.publicURL} />
          </Box>
          <AriListItemText primary={'Mon -Sat: 8am - 7pm Sun: Closed'} />
        </ListItem>
        <ListItem>
          <GatsbyLink
            to="/en/resources/forms/request-appointment"
            style={{textDecoration: 'none'}}
          >
            <BlueButton>
              Make an Appointment
            </BlueButton>
          </GatsbyLink>
        </ListItem>
      </AriList>
      <Divider />
    </Drawer>
  )
}