import * as React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { Picture } from 'react-responsive-picture'
import Hidden from '@material-ui/core/Hidden'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles'
import { useStaticQuery, graphql } from 'gatsby'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #efefef',
      zIndex: 999
    },
    icon: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0
      }
    },
    container: {
      paddingLeft: 0,
      paddingRight: 0
    }
  })

export const ContactBar = withStyles(styles)(
  ({ classes, ...props }: WithStyles<typeof styles>) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const data = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            tollFree
          }
        }
        calendarIcon: file(
          relativePath: { eq: "icons/calendar-icon.svg" }
          sourceInstanceName: { eq: "assets" }
        ) {
          publicURL
        }
        cellPhoneIcon: file(
          relativePath: { eq: "icons/cellphone-icon.svg" }
          sourceInstanceName: { eq: "assets" }
        ) {
          publicURL
        }
        instagramIcon: file(
          relativePath: { eq: "icons/instagram.svg" }
          sourceInstanceName: { eq: "assets" }
        ) {
          publicURL
        }
        twitterIcon: file(
          relativePath: { eq: "icons/twitter.svg" }
          sourceInstanceName: { eq: "assets" }
        ) {
          publicURL
        }
        facebookIcon: file(
          relativePath: { eq: "icons/facebook.svg" }
          sourceInstanceName: { eq: "assets" }
        ) {
          publicURL
        }
        pinterestIcon: file(
          relativePath: { eq: "icons/pinterest.svg" }
          sourceInstanceName: { eq: "assets" }
        ) {
          publicURL
        }
      }
    `)
    const { tollFree } = data.site.siteMetadata
    return (
      <Hidden mdDown implementation="css">
        <Container maxWidth="lg" className={classes.container}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            py={1}
          >
            <Box display="flex" flexDirection="row">
              <Picture
                className={classes.icon}
                src={data.cellPhoneIcon.publicURL}
              />
              <Typography variant="overline" display="block">
                Call Us {tollFree}
              </Typography>
              <Picture
                className={classes.icon}
                src={data.calendarIcon.publicURL}
              />
              <Typography variant="overline" display="block">
                Mon - Fri: 8am - 6pm&nbsp;&nbsp;&nbsp;&nbsp;Sat - Sun: Closed
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <a
                href="//instagram.com/aridentaloffice"
                target="_blank"
                rel="noopener"
              >
                <Picture
                  className={classes.icon}
                  src={data.instagramIcon.publicURL}
                />
              </a>
              <a
                href="//twitter.com/aridentaloffice"
                target="_blank"
                rel="noopener"
              >
                <Picture
                  className={classes.icon}
                  src={data.twitterIcon.publicURL}
                />
              </a>
              <a href="//facebook.com/aridentaloffice/"
                 target="_blank"
                 rel="noopener">
                <Picture
                  className={classes.icon}
                  src={data.facebookIcon.publicURL}
                />
              </a>
              {/* <a href="" target="_blank" rel="noopener">
                <Picture
                  className={classes.icon}
                  src={data.pinterestIcon.publicURL}
                />
              </a> */}
            </Box>
          </Box>
        </Container>
      </Hidden>
    )
  }
)
